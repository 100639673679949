var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.$route.query.type == "edit"
              ? _vm.$t("cip.plat.intf.serviceType.title.editHeadTitle")
              : _vm.$t("cip.plat.intf.serviceType.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
          }),
        ],
        1
      ),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.subTableheadBtnOptions,
          "head-title": _vm.$t(`cip.plat.intf.serviceType.title.interfaceCate`),
        },
        on: { "head-remove": _vm.headRemove, "head-add-tabs": _vm.subTableAdd },
      }),
      _c("grid-layout", {
        ref: "crud",
        staticClass: "serviceType",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.subTableOption,
          "table-data": _vm.data,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.treeMenuEdit,
        },
      }),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "60%",
                dialogTitle: _vm.$t(
                  `cip.plat.intf.serviceType.title.interfaceCate`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }